.menu-container {
  justify-content: flex-end;
}

@media (min-width: 992px) {
  .menu-container {
    width: 100%;
  }
  .menu-container > a {
    margin: 0 25px 0 25px;
  }
}

#navBar {
  transition: all 1000ms linear;
  -webkit-transition: all 1000ms linear;
  background-color: rgba(96,125,139,0.7);
}

.navbar-opaque{
    background-color: rgba(96,125,139,1.0) !important;
}