.no-padding {
  padding: 0px;
}

.no-padding .list-group-item {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-left: 0px;
  border-right: 0px;
}

/* .advertisement{
    box-shadow: 0 0 22px rgba(10, 10, 10, 0.42);
    border: 1px solid rgba(24, 45, 98, 0.7);
    position: relative;
    z-index: 99;
} */