#intro {
  background-image: url("../../resources/images/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 500px;
}

#intro .row {
  background-color: rgba(56, 56, 56, 0.66);
  padding:20px;
  border-radius: 20px;

}
